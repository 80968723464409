import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingContent1() {
    useEffect(() => {
        document.title = 'Tips for going from QBD to QBO';
        document.description = 'Some tips for making the transition from QuickBooksDesktop to QuickBooksOnline a little less painful';
    }, []);

    return (
        <div className="lg:px-28 text-gray-700">
            <h1 className="font-bold text-4xl text-gray-800">
                Tips for going from QBD to QBO
            </h1>
            <p className="text-gray-600 border-l-2 pl-4 text-sm mt-6">December 8, 2023</p>
            <p className="text-gray-600 border-l-2 pl-4 mt-0 text-sm">
                Posted by <b>Chris Ryan</b>
            </p>
            <img
                className="my-9 max-w-sm border-2 border-black mx-auto"
                src="/mb-1-pain.jpg"
                alt="oof"
            />
            <p className="text-lg mt-6">
                So apparently new licenses of QuickBooksDesktop will no longer be available in 2024. Below are a few random tidbits to make the transition to QuickBooksOnline a little less painful.
            </p>

            <p className="text-lg mt-3">
                When sharing books with your clients in QBO, you don't want them screwing up your work. Fortunately, there are a few things you can do to help the issue.
                So, you can't prevent them from changing stuff, BUT, if they do change something, there is an audit log that will show the client made the change, not you. Also,
                once you are done closing your year end accounts, you can lock the documents with a 4 digit code. OR, no password, but it prompts a warning that you're changing a closed period.
                Lastly, you don't necessarily have to share QBO access with a client. Instead, Intuit has accountant billed QBO subscriptions & user access control.
                Monthly subs are about $60-$90 a month depending on needs. You can get lower cost subs if client needs are very simple.
            </p>

            <p className="text-lg mt-3">
                In QBD, it's easy to have multiple things up so you can see everything all at once. Unfortunately, you can't EXACTLY replicate this in QBO, but you can get kinda close with multiple browser windows or tabs.
                You do need to watch out for the other windows / tabs automatically logging you out if you don't click around in them. Also, you can only open certain things in multiple windows like P&L or bal sheet.
                Other things you can't, like payroll.
            </p>

            <p className="text-lg mt-3">
                There are some pros to QBO over QBD, like better bank feed options (saving infinite time). Matching in QBD was a battle of it thought it was one specific thing and wouldn't let you pick from options.
                In QBO, it will let you search through a bunch of stuff to find a match, including multiple items to combine into one deposit.
                QBO also has a lot of free resources, training, etc. Also, you can get statements right from QBO which is nice.
            </p>

            <p className="text-lg mt-3">
                Lastly, assuming you already have the desktop version, you can call and chat with a QuickBooks rep and specifically tell them you want a 1 year desktop subscription. They will allow renewals
                of <i>existing</i> subscriptions.
            </p>
        </div>
    );
}
export default function FilesToConvertTable({ sFiles, setFiles }) {
    const onClickRemove = (fileName) => () => {
        setFiles(sFiles.filter(f => f.name !== fileName));
        return false;
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-2 border-black rounded-lg">
                            <table className="min-w-full divide-y divide-black">
                                <thead className="bg-teal-300">
                                <tr className="font-bold">
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left">
                                        File Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left">
                                        Size
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span className="sr-only">Delete</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-black bg-white">
                                {
                                    sFiles && sFiles.map((file) => (
                                        <tr key={file.name}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {file.name}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {((file.size/1024)/1024).toFixed(2)} MB
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={onClickRemove(file.name)}>
                                                    Remove
                                                </a>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

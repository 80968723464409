import {BrowserRouter, Route, Routes} from "react-router-dom";
import RootPage from "./pages/RootPage";

export default function Main() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<RootPage />}/>
        </Routes>
      </BrowserRouter>
  )
}

import CommentForm from "./components/CommentForm";
import ConfirmationSection from "./components/ConfirmationSection";
import {useEffect, useState} from "react";

export default function ContactUsPage({ user }) {
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        document.title = 'Contact Us';
        document.description = 'Reach out with any questions, concerns, or feedback';
    }, []);

    return (
        <div className="py-15">
            {
                showConfirmation ?
                <ConfirmationSection setShowConfirmation={setShowConfirmation} />
                :
                <>
                    <h2 className="text-center text-5xl font-bold tracking-tight text-gray-800 mb-5 mt-2">Get in touch</h2>
                    <div className="flex gap-x-4 justify-center">
                        <dt className="flex-none">
                            <span className="sr-only">Email</span>
                            <svg className="h-7 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path>
                            </svg>
                        </dt>
                        <dd><a className="hover:text-gray-900" href="mailto:chris@bankstatement2csv.com">chris@bankstatement2csv.com</a></dd>
                    </div>

                    <div className="max-w-2xl mx-auto my-10">
                        <div className="border-t border-gray-900/10 pt-10 text-center">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Suggestion Box</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Questions? Suggestions? Submit them below and I will get back to you.</p>
                        </div>


                        <CommentForm user={user} setShowConfirmation={setShowConfirmation} />
                    </div>
                </>
            }
        </div>
    );
}
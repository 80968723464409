import {useRef, useState} from "react";
import {post} from "../../requests";
import {useNavigate} from "react-router-dom";
import Spinner from "../../components/Spinner";

function checkEmail(email, newErrors) {
    if (email === null || email.length < 3) {
        newErrors.push("Email is required.");
    } else if (!email.includes("@") || !email.includes(".")) {
        newErrors.push("Email must have a '@' and '.'")
    }
}

function checkPassword(password, newErrors) {
    if (password === null || password.length < 9) {
        newErrors.push("Password must be at least 9 characters.");
    }
}

function checkPasswordConfirmation(passwordConfirmation, password, newErrors) {
    if (passwordConfirmation !== password) {
        newErrors.push("Password confirmation and password must match.");
    }
}

function submit(email, password, passwordConfirmation, cameFrom, setErrors, navigate, setIsCreatingUser) {
    const newErrors = [];
    checkEmail(email, newErrors);
    checkPassword(password, newErrors);
    checkPasswordConfirmation(passwordConfirmation, password, newErrors);

    if (newErrors.length === 0) {
        setIsCreatingUser(true);
        post("users", {email: email, password: password, passwordConfirmation: passwordConfirmation, cameFrom: cameFrom})
            .then(response => {
                navigate("/user-verification-pending");
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setErrors([error.response.data.details.message]);
                    setIsCreatingUser(false);
                }
            })
    } else {
        setErrors(newErrors);
    }
}

export default function UserRegistrationPage() {
    const [errors, setErrors] = useState([]);
    const [isCreatingUser, setIsCreatingUser] = useState(false);

    const email = useRef();
    const password = useRef();
    const passwordConfirmation = useRef();
    const cameFrom = useRef();

    const navigate = useNavigate();

    return (
        <div className="">
            <div className="border-2 border-black rounded-lg shadow-xl max-w-5xl mx-auto mt-9">
                <h1 className="text-center text-3xl font-bold tracking-tight text-gray-800 py-4 bg-teal-400 rounded-t border-b-2 border-black">
                    Create New Account
                </h1>
                <div className="p-6">
                    <p className="text-center mt-2 text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                             className="w-6 h-6 inline text-green-700 mr-2">
                            <path fillRule="evenodd"
                                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                  clipRule="evenodd"/>
                        </svg>
                        Free Tier (5 pages/month) — No Credit Card Required
                    </p>
                    {errors.map(error => {
                        return(
                            <p key={error} className="text-red-500 mt-2">
                                {error}
                            </p>
                        );
                    })}
                    <div className="mt-4">
                        <label className="font-bold">
                            Email
                            <input
                                className="font-normal appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                                type="email"
                                ref={email}
                            />
                        </label>
                        <p className="text-sm tracking-tight mt-2">We will send a confirmation email to this address</p>
                    </div>
                    <div className="mt-6">
                        <label className="font-bold">Password</label>
                        <input
                            className="appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                            type="password"
                            ref={password}
                        />
                        <p className="text-sm tracking-tight mt-2">9 character minimum</p>
                    </div>
                    <div className="mt-6">
                        <label className="font-bold">Password Confirmation</label>
                        <input
                            className="appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                            type="password"
                            ref={passwordConfirmation}
                        />
                        <p className="text-sm tracking-tight mt-2"></p>
                    </div>
                    <div className="mt-6">
                        <label className="font-bold">How did you hear about us?</label>
                        <select
                            className="appearance-none select
                                        rounded w-full border mt-2 py-1 pl-2 pr-6 shadow-inner
                                        focus:outline-none focus:shadow-outline"
                            ref={cameFrom}
                        >
                            <option value=""></option>
                            <option value="google">Google / Bing</option>
                            <option value="linkedin">LinkedIn</option>
                            <option value="reddit">Reddit</option>
                            <option value="word">Word of mouth</option>
                            <option value="other">Other</option>
                        </select>
                        <p className="text-sm tracking-tight mt-2"></p>
                    </div>
                </div>
                <div className="mt-4 border-t-2 border-black rounded-b p-6 text-center bg-gray-200">
                    { isCreatingUser ?
                        <Spinner classes="border-t-8 border-l-8 border-b-8 border-r-8 border-t-purple-500 border-l-purple-500 border-b-purple-500 h-8 w-8" />
                        :
                        <button
                            className="font-medium bg-purple-400 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                            type="button"
                            onClick={() => submit(email.current.value, password.current.value, passwordConfirmation.current.value, cameFrom.current.value, setErrors, navigate, setIsCreatingUser)}
                        >
                            Create Account
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}
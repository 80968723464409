import Spinner from "../../components/Spinner";
import {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {post} from "../../requests";

function checkEmail(email, newErrors) {
    if (email === null || email.length < 3) {
        newErrors.push("Email is required.");
    } else if (!email.includes("@") || !email.includes(".")) {
        newErrors.push("Email must have a '@' and '.'")
    }
}

function checkPassword(password, newErrors) {
    if (password === null || password.length < 1) {
        newErrors.push("Password is required.");
    }
}

function login(email, password, setIsLoggingIn, setUser, setErrors) {
    const newErrors = [];
    checkEmail(email, newErrors);
    checkPassword(password, newErrors);

    if (newErrors.length === 0) {
        setIsLoggingIn(true);
        post("auth/sign-in", {email: email, password: password})
            .then(response => {
                setUser(response.data);
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setErrors([error.response.data.details.message]);
                    setIsLoggingIn(false);
                }
            })
    } else {
        setErrors(newErrors);
    }
}

export default function SignInPage({ user, setUser }) {
    const [errors, setErrors] = useState([]);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const email = useRef();
    const password = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    return(
        <div className="border-2 border-black rounded-lg shadow-xl max-w-5xl mx-auto mt-9">
            <h1 className="text-center text-3xl font-bold tracking-tight text-gray-800 py-4 bg-teal-400 rounded-t border-b-2 border-black">
                Sign In to Bank Statement 2 CSV
            </h1>
            <div className="p-6">
                {errors.map(error => {
                    return(
                        <p key={error} className="text-red-500 mt-2">
                            {error}
                        </p>
                    );
                })}
                <div className="mt-4">
                    <label className="font-bold">
                        Email
                        <input
                            className="font-normal appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                            type="email"
                            ref={email}
                            autoComplete="email"
                        />
                    </label>
                </div>
                <div className="mt-6">
                    <label className="font-bold">Password</label>
                    <input
                        className="appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                        type="password"
                        ref={password}
                        autoComplete="current-password"
                    />
                </div>
                <Link to="/forgot-password" className="text-sm mt-2 text-blue-500 text-right block">Forgot your password?</Link>
            </div>
            <div className="mt-4 border-t-2 border-black rounded-b p-6 text-center bg-gray-200">
                { isLoggingIn ?
                    <Spinner classes="border-t-8 border-l-8 border-b-8 border-r-8 border-t-purple-500 border-l-purple-500 border-b-purple-500 h-8 w-8" />
                    :
                    <button
                        className="font-medium bg-purple-400 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                        type="button"
                        onClick={() => login(email.current.value, password.current.value, setIsLoggingIn, setUser, setErrors)}
                    >
                        Sign In
                    </button>
                }
            </div>
        </div>
    );
}
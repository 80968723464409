import {Link} from "react-router-dom";

export default function Preview2() {
    return(
        <div className="flex flex-row">
            <div className="basis-2/4 px-6">
                <div>
                    <Link to="/blog/2">
                        <h1 className="font-bold text-3xl text-gray-800 mb-2">The quest for a $500/month side project: First paying customer!</h1>
                    </Link>
                    <p className="text-lg text-gray-700 mb-2">I’ve always longingly read through the $500/month side project threads on HN, imagining how cool it would be to have something that I made be so useful to people that they would pay me money.</p>
                    <Link to="/blog/2" className="text-purple-700 font-bold">Read more</Link>
                </div>
            </div>
            <div className="basis-2/4 shadow">
                <img
                    className=""
                    src="/preview%20stripe%2012-3-2023.png"
                    alt="Preview Stripe"
                />
            </div>
        </div>
    );
}
import Footer from "../../components/Footer";
import {useParams} from "react-router-dom";
import Preview1 from "./components/1/Preview1";
import Content1 from "./components/1/Content1";
import Preview2 from "./components/2/Preview2";
import Content2 from "./components/2/Content2";
import {useEffect} from "react";

const entries = [
    {
        id: 2,
        preview: <Preview2 key={2}/>,
        content: <Content2/>
    },
    {
        id: 1,
        preview: <Preview1 key={1}/>,
        content: <Content1/>
    }
];

function EntryOrList({ id }) {
    if (id) {
        const entry = entries.find(e => e.id === id);
        return entry?.content;
    }

    return <EntryList/>;
}

function EntryList() {
    return (
        <div>
            {
                entries.map(e => e.preview)
            }
        </div>
    );
}

export default function BlogPage() {
    const { id } = useParams();

    useEffect(() => {
        if (id === null) {
            document.title = 'Tech Blog';
            document.description = 'Engineering Blog about start ups and entrepreneurship';
        }
    }, [id]);

    return(
        <>
            <h1 className="tracking-tight text-5xl text-center font-bold mt-6 text-gray-950">Tech Blog</h1>
            <div className="bg-white px-12 mx-auto max-w-7xl px-6 lg:px-8 mt-12 border-t-2 border-dotted pt-12">
                <EntryOrList id={parseInt(id)} />
            </div>
            <div className="mt-12">
                <Footer/>
            </div>
        </>
    );
}
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";
import {Link} from "react-router-dom";

export default function MarketingContent5() {
    useEffect(() => {
        document.title = 'What Is Bookkeeping';
        document.description = 'An overview of what bookkeeping entails';
    }, []);

    return (
        <div className="lg:px-28 text-gray-700 mx-auto">
            <img
                className="max-w-3xl mx-auto shadow-lg border-2 border-black"
                src="/arisa-chattasa-0LaBRkmH4fM-unsplash.jpg"
                alt="bookkeeping"
            />
            <p className="text-center text-sm text-gray-500">
                Photo by <a href="https://unsplash.com/@golfarisa?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Arisa Chattasa</a> on <a href="https://unsplash.com/photos/white-printer-paper-close-up-photography-0LaBRkmH4fM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
            </p>

            <h1 className="mt-6 font-bold text-4xl text-gray-800">
                How to download your bank statement as a csv file from RBC (Royal Bank of Canada)
            </h1>
            <p className="text-gray-600 border-l-2 pl-4 text-sm mt-6">December 18, 2023</p>
            <p className="text-gray-600 border-l-2 pl-4 mt-0 text-sm">
                Posted by <b>Patrick Nori</b>
            </p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Download your PDF Bank Statement</h2>
            <p className="text-lg mt-3">
                RBC only lets people download their bank statements as PDF files. BUT, there are tools to convert pdf bank statements into csv files.
                <a href="https://www.rbcroyalbank.com/ways-to-bank/tutorials/transactions-and-statements/access-account-statement.html"
                   target="_blank"
                   rel="noreferrer noopener">
                    Click here
                </a> for RBC's tutorial on how to download your bank statement
                from either the mobile app or their website.
            </p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Convert it to a CSV file</h2>
            <p className="text-lg mt-3">
                Use the conversion tool on our <Link className="text-indigo-700" to="/">home page</Link> to convert your pdf bank statement into a csv file. Simply login with your google account, upload the pdf, then download it.
                <Link to="/">
                    <img
                        className="border-2 border-black mx-auto max-w-sm mt-6 p-6 shadow"
                        src="/convert to csv.jpg"
                        alt="converter"
                    />
                </Link>
            </p>
            </div>
    );
}
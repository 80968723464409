import FilesToConvertTable from "./FilesToConvertTable";
import {jobStatusEnum} from "../ConverterPage";
import {showPageEnum} from "./Converter";
import {useEffect} from "react";

export default function FilesToConvert({ sFiles, setFiles, setConversionJobs, setShowPage, subscription }) {
    useEffect(() => {
        if (sFiles.length === 0) {
            setShowPage(showPageEnum.upload);
        }
    }, [sFiles]);

    const onClickAddConversionJobs = () => {
        const jobs = [];
        sFiles.forEach(file => {
            jobs.push({name: file.name, file: file, status: jobStatusEnum.pending});
        });

        jobs.sort((j1, j2) => {
            if (j1.name < j2.name) {
                return -1;
            } else if (j1.name > j2.name) {
                return 1;
            } else {
                return 0;
            }
        });

        setConversionJobs(jobs);
        setFiles([]);
        setShowPage(showPageEnum.fileStatus);
    }

    return (
        <>
            <div className="col-span-full flex justify-center mt-4">
                <FilesToConvertTable sFiles={sFiles} setFiles={setFiles} />
            </div>
            <div className="col-span-full mt-2 flex justify-center space-x-6">
                <button className="font-medium mt-6 bg-teal-400 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                        type="button"
                        onClick={() => {
                            window.scrollTo({top: 0});
                            setShowPage(showPageEnum.upload);
                        } }>
                    Upload More
                </button>

                <button className="font-medium mt-6 bg-teal-300 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                        type="button"
                        onClick={() => {
                            window.scrollTo({top: 0});
                            onClickAddConversionJobs(sFiles, setConversionJobs, setFiles)
                        }}>
                    Convert to CSV
                </button>
            </div>
        </>
    );
}
import FileStatusTable from "./FileStatusTable";
import {showPageEnum} from "./Converter";

export default function FileStatus({ subscription, setSubscription, setFiles, sConversionJobs, setConversionJobs, setShowPage }) {
    const onClickUploadMore = () => {
        setFiles([]);
        setConversionJobs([]);
        setShowPage(showPageEnum.upload);
    };

    return (
        <>
            <div className="col-span-full flex justify-center">
                <FileStatusTable subscription={subscription} setSubscription={setSubscription} sConversionJobs={sConversionJobs} setConversionJobs={setConversionJobs} />
            </div>
            <div className="text-center mt-6">
                <p className="font-bold text-gray-900 mb-6">
                    Make sure you've downloaded all the files, they won't be available if you upload more or go to another page!
                </p>
                { subscription.creditsRemaining > 0 &&
                    <button className="mt-2 bg-teal-400 rounded-md text-lg px-4 py-4 font-medium border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                            type="button"
                            onClick={() => {
                                window.scrollTo({top: 0});
                                onClickUploadMore();
                            }}>
                        Upload more
                    </button>
                }
            </div>
        </>
    );
}
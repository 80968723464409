import {CheckIcon} from "@heroicons/react/20/solid";
import {useEffect, useState} from "react";
import {get, post, put} from "../../../requests";
import UpgradeModal from "./UpgradeModal";
import DowngradeModal from "./DowngradeModal";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function isFreeTier(tier) {
    return tier.name === "Free";
}

function isCustomTier(tier) {
    return tier.name === "Custom";
}

function createCustomTier() {
    return {
        id: -1,
        name: "Custom",
        description: "Need more?",
        features: []
    };
}

function UpgradeButton({ tier, text, setOpenUpgradeModal, setTierToChangeTo }) {
 return (
     <a
         href={void(0)}
         aria-describedby={tier.name}
         onClick={(e) => {
             post("interestedUsers", tier.id);
             setTierToChangeTo(tier);
             setOpenUpgradeModal(true);
             e.preventDefault();
         }}
         className="font-medium mt-6 bg-teal-200 rounded-md px-4 py-4 border-2 border-gray-950 text-center text-lg
         inline-block w-full
         shadow-[3px_3px_0_black]
         transition-transform transition-shadow
         hover:translate-x-1 hover:translate-y-1 hover:shadow-none hover:cursor-pointer"
     >
         {text}
     </a>
 );
}

function DowngradeButton({ tier, text, setOpenDowngradeModal, setTierToChangeTo }) {
 return (
     <a
         href={void(0)}
         aria-describedby={tier.name}
         onClick={(e) => {
             setTierToChangeTo(tier);
             setOpenDowngradeModal(true);
             e.preventDefault();
         }}
         className="font-medium mt-6 bg-purple-400 rounded-md px-4 py-4 border-2 border-gray-950 text-center text-sm
         inline-block w-full
         shadow-[3px_3px_0_black]
         transition-transform transition-shadow
         hover:translate-x-1 hover:translate-y-1 hover:shadow-none hover:cursor-pointer"
     >
         {text}
     </a>
 );
}

function BuyLink({ href, tier, text}) {
    return(
        <a
            href={href}
            aria-describedby={tier.name}
            onClick={() => {
                post("interestedUsers", tier.id);
                return true;
            }}
            className="font-medium mt-6 bg-teal-400 rounded-md px-4 py-4 border-2 border-gray-950 text-center text-sm
             inline-block w-full
             shadow-[3px_3px_0_black]
             transition-transform transition-shadow
             hover:translate-x-1 hover:translate-y-1 hover:shadow-none hover:cursor-pointer"
        >
            {text}
        </a>
    );
}

function DisabledButton({ text }) {
    return(
        <div className="text-indigo-600 mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            {text}
        </div>
    );
}

function CTAButton({ subscription, tier, user, isSubFreeTier, subscriptionTiers, setOpenUpgradeModal, setOpenDowngradeModal, setTierToChangeTo }) {
    if (user?.email == null && isFreeTier(tier)) {
        return <div className="mt-8 font-bold">This is the free one</div>
    }
    if (user?.email == null) {
        return <div className="mt-8 font-bold">Sign in before buying</div>
    }
    if (subscription == null) {
        return null;
    }

    const buttonToRender = () => {
        if (subscription.subscriptionTierId === tier.id) {
            return <DisabledButton text="Your Current Plan" />;
        } else if (isSubFreeTier) {
            return <BuyLink
                    href={tier.checkoutLink + "?prefilled_email=" + encodeURIComponent(user?.email) + "&client_reference_id=" + user?.id}
                    tier={tier}
                    text="Buy Plan"/>;
        } else {
            const thisTierMonthlyPrice = tier.monthlyPrice;
            const userSubTierMonthlyPrice = subscriptionTiers.find(t => t.id === subscription.subscriptionTierId).monthlyPrice;
            if (thisTierMonthlyPrice < userSubTierMonthlyPrice) {
                return <DowngradeButton tier={tier} text="Downgrade" setOpenDowngradeModal={setOpenDowngradeModal} setTierToChangeTo={setTierToChangeTo}/>;
            } else {
                return <UpgradeButton tier={tier} text="Upgrade" setOpenUpgradeModal={setOpenUpgradeModal} setTierToChangeTo={setTierToChangeTo} />;
            }
        }
    };

    return buttonToRender();
}

function isSubFreeTier(subscription, tiers) {
    if (tiers && subscription) {
        const filteredTiers = tiers.filter(tier => tier.id === subscription.subscriptionTierId)
        return isFreeTier(filteredTiers[0]);
    }

    return false;
}

export default function Plans({ user, subscription, setSubscription, isOnLandingPage }) {
    const [subscriptionTiers, setSubscriptionTiers] = useState(null);
    const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
    const [openDowngradeModal, setOpenDowngradeModal] = useState(false);
    const [tierToChangeTo, setTierToChangeTo] = useState(null);

    useEffect(() => {
        get("subscriptionTiers")
            .then((resp) => {
                const { tiers, featuresByTierId } = resp.data;

                for (const tier of tiers) {
                    tier.features = featuresByTierId[tier.id];
                }

                setSubscriptionTiers(tiers);
            });
    }, [subscription]);

    return (
        <>
            <UpgradeModal open={openUpgradeModal} setOpen={setOpenUpgradeModal} tierToChangeTo={tierToChangeTo} setSubscription={setSubscription}/>
            <DowngradeModal open={openDowngradeModal} setOpen={setOpenDowngradeModal} tierToChangeTo={tierToChangeTo} setSubscription={setSubscription}/>

            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                { subscriptionTiers && subscriptionTiers.filter(tier => tier.isVisible).map((tier) => (
                    <div
                        key={tier.id}
                        className={classNames(
                            tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                            'rounded-3xl p-8 xl:p-10'
                        )}
                    >
                        <div className="flex items-center justify-between gap-x-4">
                            <h3
                                id={tier.id}
                                className={classNames(
                                    tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                                    'text-lg font-semibold leading-8'
                                )}
                            >
                                {tier.name}
                            </h3>
                            {tier.mostPopular ? (
                                <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                                    Most popular
                                </p>
                            ) : null}
                        </div>
                        <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                        {
                            (tier.monthlyPrice === 0 || tier.monthlyPrice) &&
                            <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-gray-900">${tier.monthlyPrice}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-600">Per month</span>
                            </p>
                        }

                        {
                            !isOnLandingPage &&
                            <CTAButton
                                subscription={subscription}
                                tier={tier}
                                user={user}
                                isSubFreeTier={isSubFreeTier(subscription, subscriptionTiers)}
                                subscriptionTiers={subscriptionTiers}
                                setOpenUpgradeModal={setOpenUpgradeModal}
                                setOpenDowngradeModal={setOpenDowngradeModal}
                                setTierToChangeTo={setTierToChangeTo}
                            />
                        }

                        <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                            {
                                tier.features.map(f => (
                                    <li key={f.id} className="flex gap-x-3">
                                        <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        {f.description}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                ))}
            </div>
        </>
    )
}
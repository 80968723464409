import Navbar from "../components/Navbar";
import {Outlet, Route, Routes, ScrollRestoration, useLoaderData, useLocation} from "react-router-dom";
import {get, post} from "../requests";
import ConverterPage from "./converter/ConverterPage";
import PricingPage from "./pricing/PricingPage";
import NoCreditsPage from "./NoCreditsPage";
import {useEffect, useState} from "react";
import TermsAndConditions from "./termsandconditions/TermsAndConditionsPage";
import PrivacyPolicyPage from "./privacypolicy/PrivacyPolicyPage";
import ContactUsPage from "./contactus/ContactUsPage";
import BlogPage from "./blog/BlogPage";
import SubscriptionConfirmationPage from "./subscriptionconfirmation/SubscriptionConfirmationPage";
import AccountSettingsPage from "./accountsettings/AccountSettingsPage";
import LandingPage from "./landingpage/LandingPage";
import Footer from "../components/Footer";
import ContentMarketingBlogPage from "./contentblog/ContentMarketingBlogPage";
import AboutUsPage from "./aboutus/AboutUsPage";
import UserRegistrationPage from "./userregistration/UserRegistrationPage";
import CreateOrSignInPage from "./createorsignin/CreateOrSignInPage";
import UserVerificationPendingPage from "./userverificationpending/UserVerificationPendingPage";
import UserVerificationPage from "./userverification/UserVerificationPage";
import SignInPage from "./signin/SignInPage";
import ForgotPasswordPage from "./forgotpassword/ForgotPasswordPage";
import ResetPasswordPage from "./resetpassword/ResetPasswordPage";

function useScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
}

export default function RootPage() {
    const [user, setUser] = useState(null);
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [isFreeTier, setIsFreeTier] = useState(true);

    useEffect(() => {
        if (!user) {
            get("users/self")
                .then((resp) => {
                    if (resp.status === 200) {
                        setUser(resp.data);
                    }
                });
        }
    }, []);

    useEffect(() => {
        if (user) {
            get("users/self/subscriptions")
                .then((resp) => setSubscription(resp.data));
        } else {
            // this makes sure the subscription is updated when user signs out
            setSubscription(null);
        }
    }, [user]);

    useEffect(() => {
        if (subscription) {
            get("users/self/subscriptions/is-free-tier")
                .then(response => setIsFreeTier(response.data));
        } else {
            setIsFreeTier(true);
        }
    }, [subscription])

    useScrollToTop();

    return (
        <>
            <div className="min-h-full">
                <Navbar
                    isSignedIn={!!user}
                    setUser={setUser}
                    user={user}
                    isLoadingUser={isLoadingUser}
                    setIsLoadingUser={setIsLoadingUser}
                    isFreeTier={isFreeTier}
                />
                <main>
                    <Routes>
                        <Route index element= {
                            <>
                                <LandingPage user={user} subscription={subscription} setSubscription={setSubscription}>
                                    <ConverterPage
                                        isSignedIn={!!user}
                                        subscription={subscription}
                                        setSubscription={setSubscription}
                                        isLoadingUser={isLoadingUser}
                                        setIsLoadingUser={setIsLoadingUser}
                                        setUser={setUser}
                                    />
                                </LandingPage>
                                <Footer />
                            </>
                        } />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="/contact-us" element={<ContactUsPage user={user} />} />
                        <Route path="/about-us" element={<AboutUsPage />} />
                        <Route path="/blog/:id" element={<BlogPage />} />
                        <Route path="/blog" element={<BlogPage />} />
                        <Route path="/m-blog/:id" element={<ContentMarketingBlogPage />} />
                        <Route path="/m-blog" element={<ContentMarketingBlogPage />} />
                        <Route path="/pricing" element={<PricingPage user={user} subscription={subscription} setSubscription={setSubscription} isOnLandingPage={false}/>} />
                        <Route path="/subscription-confirmation" element={<SubscriptionConfirmationPage/>} />
                        <Route path="/settings" element={<AccountSettingsPage />} />
                        <Route path="/sign-in" element={<SignInPage user={user} setUser={setUser}/>} />
                        <Route path="/user-registration" element={<UserRegistrationPage />} />
                        <Route path="/create-or-sign-in" element={<CreateOrSignInPage user={user} setUser={setUser} isLoadingUser={isLoadingUser} setIsLoadingUser={setIsLoadingUser}/>} />
                        <Route path="/user-verification-pending" element={<UserVerificationPendingPage user={user} />} />
                        <Route path="/user-verification/:uuid" element={<UserVerificationPage setUser={setUser} />} />
                        <Route path="/forgot-password" element={<ForgotPasswordPage user={user} />} />
                        <Route path="/reset-password/:uuid" element={<ResetPasswordPage user={user} />} />
                    </Routes>
                </main>
            </div>
        </>
    )
}
import { useEffect, useRef } from "react"
import { post } from "../requests"

export default function GoogleSignIn({ setUser, setIsLoadingUser }) {
    const divRef = useRef(null);

    useEffect(() => {
        // todo maybe use state to keep checking the if statement, then when this code finally runs,
        // umm, stop running this code.
        // or use a timer or something
        // or maybe making the script tag in index.js NOT be async resolved this?

        if (divRef.current && window.google) {
            window.google.accounts.id.initialize({
                client_id: "434728156139-68ookith31u7k6tm11nphi3uch1oopho.apps.googleusercontent.com",
                ux_mode: "popup",
                context: "signin",
                auto_prompt: "false",
                callback: (res, error) => {
                    setIsLoadingUser(true);
                    post("/auth/google-sign-in", res)
                        .then((res, error) => {
                            setUser(res.data);
                            setIsLoadingUser(false);
                        });
                },
            });

            window.google.accounts.id.renderButton(divRef.current, {
                theme: 'outline',
                size: 'large',
                type: 'standard',
                text: 'signin_with',
                logo_alignment: "left",
                shape: "rectangular"
            });
        }
    }, []);

    return (
        <div className="w-64 mx-auto" ref={divRef}>
        </div>
    );
};
import {Link} from "react-router-dom";
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingPreview4() {
    return(
        <div className="flex flex-row mt-9 border-t-2 border-dotted border-purple-100 pt-9">
            <div className="basis-2/4 px-6">
                <div>
                    <Link to="/m-blog/4">
                        <h1 className="font-bold text-3xl text-gray-800 mb-2">How to Categorize Transactions for Bank Statements</h1>
                    </Link>
                    <p className="text-lg text-gray-700 mb-2">Categorizing transactions on bank and credit card statements is a fundamental part of the bookkeeping process. Proper categorization helps organize financial data, track expenses, and prepare accurate financial reports. Here's a step-by-step guide on how to categorize transactions...</p>
                    <Link to="/m-blog/4" className="text-purple-700 font-bold">Read more</Link>
                </div>
            </div>
            <div className="basis-2/4">
                <img
                    className="max-w-sm shadow-lg border-2 border-black"
                    src="/beth-macdonald-uWrumIrt6wI-unsplash.jpg"
                    alt="bookkeeping"
                />
                <p className="text-sm text-gray-500">
                    Photo by <a href="https://unsplash.com/@elsbethcat?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Beth Macdonald</a> on <a href="https://unsplash.com/photos/man-in-gray-hoodie-writing-on-white-paper-uWrumIrt6wI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </p>
            </div>
        </div>
    );
}
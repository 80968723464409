import {useParams} from "react-router-dom";
import MarketingPreview1 from "./components/1/MarketingPreview1";
import MarketingContent1 from "./components/1/MarketingContent1";
import Footer from "../../components/Footer";
import MarketingPreview2 from "./components/2/MarketingPreview2";
import MarketingContent2 from "./components/2/MarketingContent2";
import MarketingPreview3 from "./components/3/MarketingPreview3";
import MarketingContent3 from "./components/3/MarketingContent3";
import MarketingPreview4 from "./components/4/MarketingPreview4";
import MarketingContent4 from "./components/4/MarketingContent4";
import MarketingPreview5 from "./components/5/MarketingPreview5";
import MarketingContent5 from "./components/5/MarketingContent5";
import {useEffect} from "react";

function EntryOrList({ id, entries }) {
    if (id) {
        const entry = entries.find(e => e.id === id);
        return entry?.content;
    }

    return (
        <div>
            {
                entries.map(e => e.preview)
            }
        </div>
    );
}

export default function ContentMarketingBlogPage() {
    const { id } = useParams();

    useEffect(() => {
        if (id === null) {
            document.title = 'Blog';
            document.description = 'Blog about book keeping and accounting';
        }
    }, [id]);

    const entries = [
        {
            id: 1,
            preview: <MarketingPreview1 key={1}/>,
            content: <MarketingContent1/>
        },
        {
            id: 2,
            preview: <MarketingPreview2 key={2}/>,
            content: <MarketingContent2/>
        },
        {
            id: 3,
            preview: <MarketingPreview3 key={3}/>,
            content: <MarketingContent3/>
        },
        {
            id: 4,
            preview: <MarketingPreview4 key={4}/>,
            content: <MarketingContent4/>
        },
        {
            id: 5,
            preview: <MarketingPreview5 key={5}/>,
            content: <MarketingContent5/>
        }
    ];

    return(
        <>
            <h3 className="tracking-tight text-5xl text-center font-bold mt-6 text-gray-950">Blog</h3>
            <div className="bg-white px-12 mx-auto max-w-7xl px-6 lg:px-8 mt-12 border-t-2 border-dotted pt-12">
                <EntryOrList id={parseInt(id)} entries={entries} />
            </div>
            <div className="mt-12">
                <Footer/>
            </div>
        </>
    );
}
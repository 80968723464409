import {Link} from "react-router-dom";
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function Preview1() {
    useEffect(() => {
        hljs.registerLanguage('java', java);
        hljs.highlightAll();
    }, []);

    return(
        <div className="flex flex-row mt-9 border-t-2 border-dotted border-purple-100 pt-9">
            <div className="basis-2/4 px-6">
                <div>
                    <Link to="/blog/1">
                        <h1 className="font-bold text-3xl text-gray-800 mb-2">How to Extract Text from a PDF using Java and PDFBox</h1>
                    </Link>
                    <p className="text-lg text-gray-700 mb-2">You want to extract text from a pdf? Do you know how to program in Java? Great! Lets dive in with some code!</p>
                    <Link to="/blog/1" className="text-purple-700 font-bold">Read more</Link>
                </div>
            </div>
            <div className="basis-2/4 shadow">
                <pre>
                    <code className="language-java text-xs">
                        {
                            `   public class CharacterCatcher extends PDFTextStripper {
        private List<TextPosition> textPositions = new ArrayList<>();
    
        public CharacterCatcher() throws IOException {
        }
    
        @Override
        protected void writeString(String string, List<TextPosition> textPositions) throws IOException {
            this.textPositions.addAll(textPositions);
        }
    
        public List<TextPosition> getTextPositions() {
            return this.textPositions;
        }
    }
`
                        }
                    </code>
                </pre>
            </div>
        </div>
    );
}
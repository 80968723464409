import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function UserVerificationPendingPage({ user }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    return(
        <div className="mt-6">
            <h1 className="text-center text-4xl font-bold tracking-tight text-gray-800 mb-5 mt-2">
                Verify your email
            </h1>
            <div className="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2 text-purple-500">
                    <path
                        d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"/>
                    <path
                        d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"/>
                </svg>

                <p className="">
                    Please check your email for a verification link. It should arrive shortly.
                </p>
            </div>
        </div>
    );
}
import Footer from "../../components/Footer";
import Converter from "./components/Converter";

export const jobStatusEnum = {
    pending: "Pending",
    inProgress: "In Progress",
    error: "Error",
    done: "Done"
}

export default function ConverterPage({ isSignedIn, subscription, setSubscription, isLoadingUser, setIsLoadingUser, setUser }) {
    return (
        <>
            <Converter
                subscription={subscription}
                setSubscription={setSubscription}
                isSignedIn={isSignedIn}
                isLoadingUser={isLoadingUser}
                setIsLoadingUser={setIsLoadingUser}
                setUser={setUser}
            />
        </>
    );
}
import {useEffect} from "react";

export default function AboutUsPage() {
    useEffect(() => {
        document.title = 'About';
        document.description = 'About Bank Statement 2 CSV';
    }, []);

    return(
        <div className="py-15 max-w-4xl mx-auto">
            <h2 className="text-center text-5xl font-bold tracking-tight text-gray-800 mt-2">About us</h2>
            <img
                className="mx-auto mt-6 max-w-sm border-2 border-black shadow"
                src="/me.jpg"
                alt=""
            />
            <p className="mt-9">
                Bank Statement 2 CSV was created by Affogato Software LLC, which is a one person company run by the person above, Chris Ryan.
                With <a className="font-medium text-indigo-700" href="https://www.linkedin.com/in/chris-p-ryan/">9 years of professional experience </a>
                building software in highly regulated industries (banking & healthcare), Chris is stepping back from the comfort of a stable corporate job and building his own software products.
            </p>
        </div>
    );
}
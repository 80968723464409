import {post} from "../../requests";
import {Link, useNavigate} from "react-router-dom";
import GoogleSignIn from "../../components/GoogleSignIn";
import {useEffect} from "react";
import Spinner from "../../components/Spinner";

export default function CreateOrSignInPage({ user, setUser, isLoadingUser, setIsLoadingUser }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    return(
        <div className="max-w-4xl mx-auto mt-9">
            <h1 className="text-gray-800 font-bold tracking-tight text-center text-3xl bg-teal-400 border-t-2 border-l-2 border-r-2 border-black rounded-t-lg p-4">
                Welcome to Bank Statement 2 CSV
            </h1>

            <div className="border-2 border-black rounded-b-lg p-6">
                <Link
                    to="/user-registration"
                    className="font-medium mt-6 bg-purple-500 rounded-md px-4 py-4 border-2 border-gray-950 text-center text-2xl
                             inline-block w-full
                             shadow-[3px_3px_0_black]
                             transition-transform transition-shadow
                             hover:translate-x-1 hover:translate-y-1 hover:shadow-none hover:cursor-pointer"
                >
                    Create New Account
                </Link>

                <Link
                    to="/sign-in"
                    className="font-medium mt-6 bg-purple-400 rounded-md px-4 py-4 border-2 border-gray-950 text-center text-2xl
                             inline-block w-full
                             shadow-[3px_3px_0_black]
                             transition-transform transition-shadow
                             hover:translate-x-1 hover:translate-y-1 hover:shadow-none hover:cursor-pointer"
                >
                    Sign In
                </Link>

                <div className="mt-6">
                    {
                        isLoadingUser ? <div className="w-12 mx-auto"><Spinner classes="border-t-8 border-l-8 border-b-8 border-r-8 border-t-purple-500 border-l-purple-500 border-b-purple-500 h-8 w-8" /></div>
                            : <GoogleSignIn setUser={setUser} setIsLoadingUser={setIsLoadingUser} />
                    }
                </div>
            </div>
        </div>
    );
}
import {NavLink} from "react-router-dom";

export default function Footer() {
    return (
        <footer className="bg-gray-950">
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                <span className="text-sm text-gray-100 sm:text-center">
                    © 2024
                    <a href="https://bankstatement2csv.com/" className="hover:underline"> Affogato Software LLC™</a>.
                    All Rights Reserved.
                </span>
                <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-400 sm:mt-0">
                    <li>
                        <NavLink
                            key={"PrivacyPolicy"}
                            to={"/privacy-policy"}
                            className="mr-4 hover:underline md:mr-6"
                        >
                            Privacy Policy
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            key={"TermsAndConditions"}
                            to={"/terms-and-conditions"}
                            className="mr-4 hover:underline md:mr-6"
                        >
                            Terms & Conditions
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            key={"Blog"}
                            to={"/blog"}
                            className="mr-4 hover:underline md:mr-6"
                        >
                            Tech Blog
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            key={"MBlog"}
                            to={"/m-blog"}
                            className="mr-4 hover:underline md:mr-6"
                        >
                            Blog
                        </NavLink>
                    </li>
                </ul>
            </div>
        </footer>
    );
}
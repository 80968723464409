export default function Header() {
    return (
        <>
            <div className="mx-auto max-w-4xl text-center">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-800 sm:text-5xl">
                    Pricing plans for everyone
                </h1>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Choose an existing plan that works for you or reach out to us for a customized plan
            </p>
        </>
    )
}
import {Link} from "react-router-dom";
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingPreview3() {
    return(
        <div className="flex flex-row mt-9 border-t-2 border-dotted border-purple-100 pt-9">
            <div className="basis-2/4 px-6">
                <div>
                    <Link to="/m-blog/3">
                        <h1 className="font-bold text-3xl text-gray-800 mb-2">What Does Bookkeeping Consist Of?</h1>
                    </Link>
                    <p className="text-lg text-gray-700 mb-2">A collection of responses from actual bookkeepers</p>
                    <Link to="/m-blog/3" className="text-purple-700 font-bold">Read more</Link>
                </div>
            </div>
            <div className="basis-2/4">
                <img
                    className="max-w-sm shadow-lg border-2 border-black"
                    src="/tim-gouw-bwki71ap-y8-unsplash.jpg"
                    alt="bookkeeping"
                />
                <p className="text-sm text-gray-500">
                    Photo by <a href="https://unsplash.com/@punttim?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Tim Gouw</a> on <a href="https://unsplash.com/photos/three-women-sitting-beside-table-bwki71ap-y8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </p>
            </div>
        </div>
    );
}
import {useEffect, useState} from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import {post} from "../../../requests";

function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

function submitForm(event,
                    setShowConfirmation,
                    comment, setCommentError,
                    email, setEmailError,
                    name) {
    event.preventDefault();

    if (isBlank(comment)) {
        setCommentError("Please enter a question or suggestion");
    } else if (comment.length > 2000) {
        setCommentError("Please shorten your question / suggestion. If you can't, then please email us directly at chris@bankstatement2csv.com")
    }

    if (isBlank(email)) {
        setEmailError("Please enter your email so we can get back to you");
    } else if (email.length > 250) {
        setEmailError("If your email really is this long, please email me directly");
    }

    post("comments", {
        name: name,
        email: email,
        comment: comment
    }).then(response => {
        setShowConfirmation(true);
    });
}

export default function CommentForm({ user, setShowConfirmation }) {
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(null);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(null);

    useEffect(
        () => {
            if (user) {
                setEmail(user.email);
            }
        }, [user]);

    return (
        <form className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Suggestions/Questions
                </label>
                <span className="text-red-600">{ commentError }</span>
                <div className="mt-2">
                        <textarea id="about" name="about" rows="3"
                                  value={comment} onChange={e => { setComment(e.target.value); setCommentError(""); }}
                                  className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                </div>
            </div>

            <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                </label>
                <div className="mt-2">
                    <input id="email" name="email" type="email" autoComplete="email"
                           value={email} onChange={e => setEmail(e.target.value)}
                           className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                </label>
                <div className="mt-2">
                    <input type="text" name="first-name" id="first-name" autoComplete="given-name"
                           value={name} onChange={e => setName(e.target.value)}
                           className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="col-span-4">
                <PrimaryButton
                    buttonText="Submit"
                    onClick={e =>
                        submitForm(e,
                            setShowConfirmation,
                            comment, setCommentError,
                            email, setEmailError,
                            name, setNameError)
                    }
                />
            </div>
        </form>
    );
}
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {get, post} from "../../requests";
import Spinner from "../../components/Spinner";

function MailIcon() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2 text-green-500">
            <path
                d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"/>
            <path
                d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"/>
        </svg>
    );
}

export default function UserVerificationPage({ setUser }) {
    const { uuid } = useParams();

    const [isValidating, setIsValidating] = useState(true);

    useEffect(()=>{
        if (uuid) {
            post("users/self/verify-email/" + uuid)
                .then(response => {
                    get("users/self").then(response => {
                        setUser(response.data);
                        setIsValidating(false);
                    });
                });
        }
    },[]);

    return(
        <div className="mt-9">
            <h1 className="text-center text-4xl font-bold tracking-tight text-gray-800 mb-5 mt-2">
                { !isValidating && "Welcome!" }
            </h1>
            <div className="flex justify-center mt-9">
                { isValidating ? <Spinner classes="mr-2 border-t-purple-400 border-l-purple-400 border-b-purple-400 h-6 w-6" /> : <MailIcon /> }

                <p className="text-lg">
                    { isValidating ? "Verifying your email..." : "Your email has been successfully verified."}
                </p>
            </div>
            { !isValidating &&
                <Link
                    to="/"
                    className="font-medium mt-9 bg-purple-400 rounded-md px-4 py-4 border-2 border-gray-950 text-center text-xl max-w-sm mx-auto
                             block w-full
                             shadow-[3px_3px_0_black]
                             transition-transform transition-shadow
                             hover:translate-x-1 hover:translate-y-1 hover:shadow-none hover:cursor-pointer"
                >
                    Get Started
                </Link>
            }
        </div>
    );
}
import {Link} from "react-router-dom";
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingPreview5() {
    return(
        <div className="flex flex-row mt-9 border-t-2 border-dotted border-purple-100 pt-9">
            <div className="basis-2/4 px-6">
                <div>
                    <Link to="/m-blog/5">
                        <h1 className="font-bold text-3xl text-gray-800 mb-2">How to download bank statement as csv file from RBC</h1>
                    </Link>
                    <p className="text-lg text-gray-700 mb-2">
                        A quick guide on how to download your rbc bank statement as a csv file
                    </p>
                    <Link to="/m-blog/5" className="text-purple-700 font-bold">Read more</Link>
                </div>
            </div>
            <div className="basis-2/4">
                <img
                    className="max-w-sm shadow-lg border-2 border-black"
                    src="/arisa-chattasa-0LaBRkmH4fM-unsplash.jpg"
                    alt="bookkeeping"
                />
                <p className="text-sm text-gray-500">
                    Photo by <a href="https://unsplash.com/@golfarisa?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Arisa Chattasa</a> on <a href="https://unsplash.com/photos/white-printer-paper-close-up-photography-0LaBRkmH4fM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </p>
            </div>
        </div>
    );
}
import {Link} from "react-router-dom";
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingPreview1() {
    return(
        <div className="flex flex-row pt-9">
            <div className="basis-2/4 px-6">
                <div>
                    <Link to="/m-blog/1">
                        <h1 className="font-bold text-3xl text-gray-800 mb-2">Tips for going from QBD to QBO</h1>
                    </Link>
                    <p className="text-lg text-gray-700 mb-2">So apparently new licenses of QuickBooksDesktop will no longer be available in 2024. Below are a few random tidbits to make the transition to QuickBooksOnline a little less painful.</p>
                    <Link to="/m-blog/1" className="text-purple-700 font-bold">Read more</Link>
                </div>
            </div>
            <div className="basis-2/4">
                <img
                    className="max-w-sm shadow-lg border-2 border-black"
                    src="/mb-1-pain.jpg"
                    alt="oof"
                />
            </div>
        </div>
    );
}
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingContent3() {
    useEffect(() => {
        document.title = 'What Does Bookkeeping Consist Of?';
        document.description = 'An overview of what bookkeeping entails from actual bookkeepers';
    }, []);

    return (
        <div className="lg:px-28 text-gray-700">
            <img
                className="border-2 border-black mx-auto max-w-4xl"
                src="/tim-gouw-bwki71ap-y8-unsplash.jpg"
                alt="bookkeeping"
            />
            <h1 className="mt-6 font-bold text-4xl text-gray-800">
                What Does Bookkeeping Consist Of?
            </h1>
            <p className="text-gray-600 border-l-2 pl-4 text-sm mt-6">December 13, 2023</p>
            <p className="text-gray-600 border-l-2 pl-4 mt-0 text-sm">
                Posted by <b>Patrick Nori</b>
            </p>

            <p className="text-lg mt-6">
                A collection of responses from actual bookkeepers:
            </p>

            <p className="text-lg mt-6 mb-3">
                My most intricate clients demand comprehensive support, excluding tax preparation. Although I'm not yet a CPA, I handle daily transaction entries, monthly reconciliations, prepaid expenses reconciliation, vendor and subcontractor management, payroll oversight (including benefits tracking and management, onboarding into Gusto, etc.), invoicing, tax payments, and expense management.

                Bank rules simplify daily data entry since I have everything precisely coded; any unusual transactions are promptly flagged. A weekly or biweekly check-in with the client is usually sufficient, taking no more than 20 minutes. During these check-ins, we review any outstanding items such as unclear transactions, upcoming renewals, items to cancel or downgrade, areas for improvement, and financial opportunities.

                Most of the processes become automated after the initial few weeks of onboarding. I collaborate with the CPA at the year-end and for a mid-year check-in, ensuring they have all necessary information and the books are in good order, minimizing the need for unnecessary adjustments.

                The most time-consuming aspect is initially onboarding a client, understanding their expenses, communication style, cleaning up as required, and establishing standard protocols and training. My goal is to simplify bookkeeping for clients, ensuring they can easily handle tasks, pull reports, or manage in my absence. Clients quickly adapt to the efficient processes we implement, and any who find them challenging are swiftly offboarded.
            </p>
            <a className="text-sm" href="https://old.reddit.com/r/Bookkeeping/comments/189fxgr/bookkeepers_what_does_your_job_consist_of/kbro9in/">
                - /u/LBAIGL
            </a>

            <p className="text-lg mt-6 mb-3 pt-6 border-t-2">
                I reside in a rural area, a small town that shapes my unique professional experience. I collaborate with a local tax preparation firm to handle bookkeeping for their clients, and I run a small home business catering to clients who opt not to use the tax prep firm.

                From a CPA's perspective, my work might seem mundane. All my clients are small businesses, mainly individuals with limited financial knowledge. Primarily, my responsibilities include expense coding, reconciliation, and payroll tasks. Like the other poster, I've automated a significant portion of my work, but I manually enter transactions for two clients with a low transaction volume. For one client, I generate invoices from a spreadsheet obtained from their time tracking software. Additionally, I handle sales tax payments and untangle/clean up books for some tax prep clients who often transition into regular clients due to difficulties navigating the process.

                As mentioned by another poster, a substantial amount of time is dedicated to onboarding clients, building trust, and addressing cleanup tasks. This is especially true during instances of government letters regarding payroll taxes, concerns over workers' comp audits, or panic regarding requests like, "My CPA wants financials, and I don't even know where to start—help?" While I may not consider my work as high-level bookkeeping, the region is populated with small businesses whose owners may not grasp concepts like assets, expenses, and equity. I often find myself assisting those set adrift by CPAs who establish an S Corp without clear instructions or businesses facing the refusal of their accountant to handle another year of taxes involving disorganized paperwork—enter the bookkeeper!

                In truth, most small business owners in this area, particularly sole proprietors or LLCs with minimal staff and manual inventory, excel in their craft but feel embarrassed and anxious about financial matters. They lack knowledge about profit and loss statements and how to generate one. My role involves meeting them where they are, guiding them with patience, and gradually helping them establish clean, compliant accounts.
            </p>
            <a className="text-sm" href="https://old.reddit.com/r/Bookkeeping/comments/189fxgr/bookkeepers_what_does_your_job_consist_of/kbsf5tr/">
                - /u/Oldladyphilosopher
            </a>

            <p className="text-lg mt-6 mb-3 pt-6 border-t-2">
                The degree of complexity can vary significantly, and you'll need to tailor your approach to what makes the most sense for you and your firm. Managing business owners with one or two accounts and a straightforward balance sheet is relatively straightforward. The process typically involves monthly expense and income categorization, sending a spreadsheet of questions for clarification, inquiring about asset transactions or changes in financial instruments, and updating and reconciling based on the owner's responses.

                For more intricate businesses with multiple entities, numerous loans, assets, inventory, point-of-sale (POS) systems, third-party sales and billing channels like PayPal and Venmo, payroll, 1099 tracking and reports, leaseholder improvements, grant fund tracking, sales tax, receipt handling, and so on, the scope of work expands considerably.

                In my experience, some seemingly quiet businesses may face unexpected audits from grant issuers, others may maintain a second set of concealed books, and some may consistently establish new entities. The key is deciding the level of complexity you're comfortable managing. Many bookkeepers prefer not to handle the intricacies I deal with. A significant portion of my work is evolving into a fractional CFO role. For instance, if I handle vendor payments on behalf of the client, I operate as a fractional CFO, and they pay my full bookkeeping rate for bill payments.

                Consider whether you want to store client files, including purchase and sale agreements, bank information, passwords, etc. If so, involving IT for security measures and setting up a digital filing system is essential.

                The level of complexity in this field can range from very simple to highly intricate, and it ultimately depends on your preferences and the services you want to offer.
            </p>
            <a className="text-sm" href="https://old.reddit.com/r/Bookkeeping/comments/189fxgr/bookkeepers_what_does_your_job_consist_of/kbs32j9/">
                - /u/Sudden_Set_9316
            </a>

        </div>
    );
}
// CheckSuccess-circle animation: checkmarkCircleShimmer 1s linear both,drawCircle 1s linear both;
import {useEffect} from "react";

export default function SubscriptionConfirmationPage() {
    useEffect(() => {
        window.gtag_report_conversion();
        window.lintrk('track', { conversion_id: 15586970 });
    }, []);

    return(
        <>
            <div className="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-10 h-10 text-green-600">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
            </div>
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">Thanks for subscribing</h2>
            <div className="text-center max-w-2xl mx-auto my-10">
                A payment to Affogato Software LLC will appear on your statement.
            </div>
        </>
    );
}
import {useEffect, useState} from "react";
import {get, postForm} from "../../../requests";
import useInterval from "../../../useInterval";

export default function FileStatusTable({ subscription, setSubscription, sConversionJobs, setConversionJobs }) {
    const [inProgressJob, setInProgressJob] = useState(null);

    useInterval(() => {
        if (inProgressJob?.conversionJobId) {
            get("/conversion-job/" + inProgressJob.conversionJobId + "/status")
                .then(response => {
                    const conversionJobStatus = response.data.conversionJobStatus;
                    const subscription = response.data.subscription;
                    if (conversionJobStatus === 'PROCESSED') {
                        const updatedJobs = sConversionJobs
                            .map(job => {
                                if (job.name === inProgressJob.name) {
                                    return {...job, status: "Done", csvFileName: response.data.fileUuid + ".csv"};
                                } else {
                                    return job;
                                }
                            })

                        setConversionJobs(updatedJobs);
                        setInProgressJob(null);
                    } else if (conversionJobStatus === 'FAILED' || conversionJobStatus === 'PROCESSING_FAILED') {
                        const updatedJobs = sConversionJobs
                            .map(job => {
                                if (job.name === inProgressJob.name) {
                                    return {...job, status: "Error", errorMessage: "Error, please contact support"};
                                } else {
                                    return job;
                                }
                            })

                        setConversionJobs(updatedJobs);
                        setInProgressJob(null);
                    }

                    if (subscription) {
                        setSubscription(subscription);
                    }
                });
        }
    }, 1000);

    useEffect(() => {
        if (sConversionJobs.filter(job => job.status === "In Progress").length === 0) {
            const pendingJobs = sConversionJobs.filter(job => job.status === "Pending");
            if (pendingJobs.length > 0) {
                const pendingJob = pendingJobs[0];

                postForm("/pdfs/convertToCsv", [pendingJob])
                    .then(response => {
                        const updatedJobs = sConversionJobs.map(job => {
                            if (job.name === pendingJob.name) {
                                return {...job, status: "In Progress", conversionJobId: response.data};
                            } else {
                                return job;
                            }
                        });

                        setConversionJobs(updatedJobs);
                        setInProgressJob({...pendingJob, status: "In Progress", conversionJobId: response.data});
                    })
                    .catch(error => {
                        const updatedJobs = sConversionJobs
                            .map(job => {
                                if (job.name === pendingJob.name) {
                                    const status = error.response?.data?.status;
                                    if (status === 400) {
                                        const errorCode = error.response?.data?.title;
                                        const errorMessage = error.response?.data?.details?.message;
                                        return {
                                            ...job,
                                            status: errorCode ? errorCode : "Error",
                                            errorMessage: errorMessage ? errorMessage : "Error, please contact support"
                                        };
                                    }
                                } else {
                                    return job;
                                }
                            })

                        setConversionJobs(updatedJobs);
                    });
            }
        }
    }, [sConversionJobs, setConversionJobs]);

    const onClickDownloadCsv = (pdfFileName, csvFileName) => () => {
        // Downloading
        const jobsWithDownloading = sConversionJobs.map(job => {
            if (job.name === pdfFileName) {
                return {...job, status: "Downloading"};
            } else {
                return job;
            }
        });
        setConversionJobs(jobsWithDownloading);

        get("/csvs/" + csvFileName.replace(".csv", "") + "/download", { responseType: "blob" })
            .then(response => {
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                anchorElement.download = pdfFileName.replace(".pdf", ".csv");

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .then(() => {
                const jobsWithDownloaded = sConversionJobs.map(job => {
                    if (job.name === pdfFileName) {
                        return {...job, status: "Downloaded"};
                    } else {
                        return job;
                    }
                });
                setConversionJobs(jobsWithDownloaded);
            })
            .catch(error => {
                console.log('error: ', error);
            });
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="my-4">
                <p><b>Please be patient</b> while we process your statements with our upgraded algorithm.</p>
                <p>Bank statements generally take 30 seconds to convert each page.</p>
                <p>Image based bank statements take longer.</p>
            </div>
            <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-2 border-black sm:rounded-lg">
                            <table className="min-w-full divide-y-2 divide-black">
                                <thead className="bg-teal-300">
                                <tr className="font-bold">
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-gray-900 sm:pl-6">
                                        File Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-gray-900">
                                        Status
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y-2 divide-black bg-white">
                                {
                                    sConversionJobs && sConversionJobs.map((file) => (
                                        <tr key={file.name}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {file.name}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                                {
                                                    file.errorMessage &&
                                                    <span className="inline-flex items-center gap-x-1.5 rounded-md text-sm px-2 py-1 border-2 border-red-700 font-bold text-red-700 bg-red-100">
                                                        <svg className="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                                                            <circle cx={3} cy={3} r={3} />
                                                        </svg>
                                                        {file.errorMessage}
                                                    </span>
                                                }
                                                {   file.status === "Done" &&
                                                    <button className="bg-teal-400 rounded-md text-sm px-2 py-2 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                                                            type="button"
                                                            onClick={onClickDownloadCsv(file.name, file.csvFileName)}>
                                                        Download CSV
                                                    </button>
                                                }
                                                {
                                                    file.status === "Downloading" &&
                                                    <div>
                                                        <span className={"pr-2"}>
                                                            Downloading
                                                        </span>
                                                        <div
                                                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                            role="status">
                                                        </div>
                                                    </div>
                                                }
                                                { file.status === "Downloaded" &&
                                                    <span className="inline-flex items-center gap-x-1.5 rounded-md text-sm py-1 px-2 border-2 border-teal-700 font-bold text-teal-700 bg-teal-100">
                                                        <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                                                            <circle cx={3} cy={3} r={3} />
                                                        </svg>
                                                        Downloaded
                                                    </span>
                                                }
                                                { file.status === "In Progress" &&
                                                    <div>
                                                        <span className={"pr-2"}>
                                                            In Progress
                                                        </span>
                                                        <div
                                                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                            role="status">
                                                        </div>
                                                    </div>
                                                }
                                                { file.status === "Pending" && file.status }
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import {Link} from "react-router-dom";
import {useEffect} from "react";
import hljs from "highlight.js/lib/core";

export default function Content2() {
    useEffect(() => {
        document.title = 'The quest for a $500/month side project: First paying customer!';
        document.description = 'I’ve always longingly read through the $500/month side project threads on HN, imagining how cool it would be to have something that I made be so useful to people that they would pay me money.';
    }, []);

    return(
        <div className="lg:px-28 text-gray-700">
            <h1 className="font-bold text-4xl text-gray-800">
                The quest for a $500/month side project: First paying customer!
            </h1>
            <p className="text-gray-600 border-l-2 pl-4 text-sm mt-6">December 3, 2023</p>
            <p className="text-gray-600 border-l-2 pl-4 mt-0 text-sm">
                Posted by <b>Chris Ryan</b>
            </p>
            <p className="text-lg mt-6">
                I’ve always longingly read through the $500/month side project threads on HN, imagining how cool it would be to have something that I made be so useful to people that they would pay me money. But the same problem always came up, what the heck do I build? Common advice I’d hear was “Look around and build something that already exists, but better”- OK awesome! So I’d look around, build a thing, and then wait for the money to start rolling in.
                <br /> <br /> and wait….. <br/>
                and wait………... <br/>
                and waaaiit....................
            </p>
            <div className="flex justify-center mt-6">
                <img
                    className="max-w-xl"
                    src="/bored%20dog.jpg"
                    alt=""
                />
            </div>
            <p className="text-lg mt-6">
                One day, I read about a pdf bank statement to csv converter. And then I read about another one. Both of them made by solo developers. Then a third one! And I think they all make money? Okay, cool- I’ve never parsed pdfs before, but this sounded kind of fun. Lets do it!
            </p>
            <p className="text-lg mt-6">
                Over the course of a week or so I built a parser for my Chase bank statement. The algorithm works roughly like this:

                <ul className="list-disc mt-3 pl-6">
                    <li><Link className="text-purple-700" to="/blog/1">Use PDFBox to extract all of the text from a PDF</Link></li>
                    <li>Looking at the text row by row, look for the transaction table’s headers</li>
                    <li>Categorize a row’s data based on if the word overlaps with a header or not. For words that don’t overlap, keep looking to the left until a header is found, and categorize it under that.</li>
                    <li>If a row contains the word ‘total’ and a number, it’s the end of a table.</li>
                    <li>Filter out all the rows we just looked at and start over by looking for more transaction headers.</li>
                    <li>Repeat until no more headers are found.</li>
                </ul>
            </p>
            <p className="text-lg mt-6">
                Ok great, done. Now, instead of waiting for users to magically find my site, I'll try google ads. I didn’t really know what I was doing, so I just fumbled my way through the ad creation process. I set the daily dollar allowance to whatever google thought best ($32) and waited. As the days progressed, most people tried the app, got a failure, and left forever. But, a few people actually bothered to email me with complaints! Turned out, just about everyone ignored my super helpful message about only supporting Chase bank statements.
            </p>
            <div className="flex justify-center mt-6">
                <img
                    className="max-w-xl"
                    src="/google%20ads%2012-3-2023.png"
                    alt=""
                />
            </div>
            <p className="text-lg mt-6">
                I started writing bank statement converters for anyone that bothered to contact me about a failed conversion. A few weeks of this goes by. A lot of ad money has been spent, and not a single cent has come in.
            </p>
            <div className="flex justify-center mt-6">
                <img
                    className="max-w-xl"
                    src="/preview%20stripe%2012-3-2023.png"
                    alt=""
                />
            </div>
            <p className="text-lg mt-6">
                Until one magical morning… at 1:40am MST, Stripe notified me of my <b>first sign up!</b> A $5/month subscription, minus stripe’s fee, equals $4.82 / month, MY FIRST PAYING CUSTOMER! WOOHOO!
            </p>
            <p className="text-lg mt-6">
                The google ad spend of $904 vs my raging income of $4.82 doesn’t feel sustainable though. Maybe I’ll try facebook or twitter ads next? Or maybe I can optimize my google ads? I'm not really sure yet.
            </p>
        </div>
    );
}
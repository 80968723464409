import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Spinner from "../../components/Spinner";
import {post} from "../../requests";

function checkEmail(email, newErrors) {
    if (email === null || email.length < 3) {
        newErrors.push("Email is required.");
    } else if (!email.includes("@") || !email.includes(".")) {
        newErrors.push("Email must have a '@' and '.'")
    }
}

function sendResetLink(email, setIsSendingEmail, setResetSent, setErrors) {
    const newErrors = [];
    checkEmail(email, newErrors);

    if (newErrors.length === 0) {
        setErrors(newErrors);
        setIsSendingEmail(true);
        post("auth/forgot-password", email)
            .then(response => {
                setResetSent(true);
                setIsSendingEmail(false);
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setErrors([error.response.data.details.message]);
                    setIsSendingEmail(false);
                }
            })
    } else {
        setErrors(newErrors);
    }
}

function SubmitButton({ isSendingEmail, email, setIsSendingEmail, setResetSent, setErrors }) {
    return(
        <>
            { isSendingEmail ?
                <Spinner classes="border-t-8 border-l-8 border-b-8 border-r-8 border-t-purple-500 border-l-purple-500 border-b-purple-500 h-8 w-8" />
                :
                <button
                    className="font-medium bg-purple-400 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                    type="button"
                    onClick={() => sendResetLink(email.current.value, setIsSendingEmail, setResetSent, setErrors)}
                >
                    Send Reset Password Link
                </button>
            }
        </>
    );
}

export default function ForgotPasswordPage({ user }) {
    const [errors, setErrors] = useState([]);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [resetSent, setResetSent] = useState(false);

    const email = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    return(
        <div className="border-2 border-black rounded-lg shadow-xl max-w-5xl mx-auto mt-9">
            <h1 className="text-center text-3xl font-bold tracking-tight text-gray-800 py-4 bg-teal-400 rounded-t border-b-2 border-black">
                Forgot Password for Bank Statement 2 CSV
            </h1>
            <div className="p-6">
                {errors.map(error => {
                    return(
                        <p key={error} className="text-red-500 mt-2">
                            {error}
                        </p>
                    );
                })}
                <div className="mt-4">
                    {
                        resetSent ?
                            <p className="text-lg mt-2">If we find a match for your email, we will send a password reset link to it shortly.</p>
                            :
                            <label className="font-bold">
                                Email
                                <input
                                    className="font-normal appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                                    type="email"
                                    ref={email}
                                    autoComplete="email"
                                />
                            </label>
                    }
                </div>
            </div>
            <div className="mt-4 border-t-2 border-black rounded-b p-6 text-center bg-gray-200">
                {
                    resetSent ? null :
                        <SubmitButton
                            email={email}
                            isSendingEmail={isSendingEmail}
                            setIsSendingEmail={setIsSendingEmail}
                            setResetSent={setResetSent}
                            setErrors={setErrors} />
                }
            </div>
        </div>
    );
}
import {Fragment, useEffect, useRef, useState} from "react";
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {Link, useNavigate} from "react-router-dom";
import {get, put} from "../../requests";
import Spinner from "../../components/Spinner";

function cancelSubscription(setCanceling, setSuccess, setFailed) {
    setCanceling(true);
    put("/users/self/subscriptions/cancel")
        .then(response => {
            setCanceling(false);
            setSuccess(true);
        })
        .catch(error => {
            setCanceling(false);
            setFailed(true);
        })
}

function CancelSubModal({ open, setOpen }) {
    const cancelButtonRef = useRef(null)

    const [canceling, setCanceling] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (success) {
            navigate("/")
        }
    }, [success]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Cancel Subscription
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to cancel your subscription?
                                                <br />
                                                Cancelling your subscription will revert you to the Free Tier.
                                                <br/>
                                                You will keep any credits you already paid for.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    { canceling ? <Spinner /> :
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => cancelSubscription(setCanceling, setSuccess, setFailed)}
                                    >
                                        Cancel Subscription
                                    </button> }
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Nevermind
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default function AccountSettingsPage() {
    const [openCancelSubModal, setOpenCancelSubModal] = useState(false)
    const [nextBillingDate, setNextBillingDate] = useState(null);
    const [updateBillingInfoUrl, setUpdateBillingInfoUrl] = useState(null);

    useEffect(() => {
            get("users/self/subscriptions/next-billing-date")
                .then(response => {
                    var date = new Date(1704743955 * 1000);
                    setNextBillingDate(date);
                });

            get("users/self/subscriptions/update-billing-info-url")
                .then(response => {
                    if (response.data) {
                        setUpdateBillingInfoUrl(response.data);
                    }
                })
        }, []);

    return (
        <>
            <div>
                <h1 className="mt-2 text-5xl font-bold tracking-tight text-gray-800 text-center">
                    Account Settings
                </h1>

                <div className="mt-9 border-2 border-black rounded-md max-w-xl mx-auto px-6 py-6 flex flex-col bg-orange-200">
                    <h2 className="text-center text-2xl font-bold text-gray-900">Manage Subscription</h2>
                    <div className="mt-2 text-center font-medium">
                        Next Billing Date: {nextBillingDate ? nextBillingDate.toLocaleDateString() + " " + nextBillingDate.toLocaleTimeString() : <Spinner classes="border-teal-400"/>}
                    </div>
                    {/*<div>*/}
                    {/*    <a href={updateBillingInfoUrl}*/}
                    {/*       className="w-full text-center font-medium mt-6 bg-white rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"*/}
                    {/*       type="button"*/}
                    {/*    >*/}
                    {/*        {updateBillingInfoUrl ? "Update Billing Information" : <Spinner classes="border-teal-400"/>}*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    <div>
                        <Link to="/pricing"
                              className="w-full text-center font-medium mt-9 bg-teal-400 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                              type="button"
                        >
                            Upgrade or Downgrade Subscription
                        </Link>
                    </div>
                    {/*<div>*/}
                    {/*    <button className="w-full block font-medium mt-9 bg-purple-400 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"*/}
                    {/*            type="button"*/}
                    {/*            onClick={() => setOpenCancelSubModal(true)}*/}
                    {/*    >*/}
                    {/*        Cancel Subscription*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>

            <CancelSubModal open={openCancelSubModal} setOpen={setOpenCancelSubModal}/>
        </>
    );
}
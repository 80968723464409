import Header from "./components/Header";
import Plans from "./components/Plans";
import {useEffect, useReducer, useState} from "react";
import {get} from "../../requests";

export default function PricingPage({ user, subscription, setSubscription, isOnLandingPage }) {

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        document.title = 'Pricing';
        document.description = 'Prices for everyone';

        // detect if bfcache stuff happened. this avoids issue where hitting back from stripe would lead to react not loading anything, so page was rendering
        // as if the user was not signed in, even though they are
        window.onpageshow = function(event) {
            forceUpdate();
        };
    }, []);

    return (
        <div className="bg-white py-15">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <Header />
                <Plans user={user} subscription={subscription} setSubscription={setSubscription} isOnLandingPage={isOnLandingPage}/>
            </div>
        </div>
    )
}

import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingContent2() {
    useEffect(() => {
        document.title = 'What Is Bookkeeping';
        document.description = 'An overview of what bookkeeping entails';
    }, []);

    return (
        <div className="lg:px-28 text-gray-700">
            <img
                className="border-2 border-black mx-auto"
                src="/bookkeeping-image-rVs.jpeg"
                alt="bookkeeping"
            />
            <h1 className="mt-6 font-bold text-4xl text-gray-800">
                What Is Bookkeeping
            </h1>
            <p className="text-gray-600 border-l-2 pl-4 text-sm mt-6">December 12, 2023</p>
            <p className="text-gray-600 border-l-2 pl-4 mt-0 text-sm">
                Posted by <b>Patrick Nori</b>
            </p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Introduction</h2>
            <p className="text-lg mt-3">Imagine you have a money diary for your piggy bank. That's what bookkeeping is for businesses. It helps them keep track of where they get money (like selling toys) and where they spend it (like buying more toys), so they can be smart with their money choices.</p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Recording Money Moves</h2>
            <p className="text-lg mt-3">Bookkeeping is like writing down every time a lemonade stand gets money from selling lemonades or spends money to buy lemons and cups. It's like making a big storybook about the lemonade stand's money adventures, so everyone knows how much lemonade they sold and how much money they made.</p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Categorizing Financial Data</h2>
            <p className="text-lg mt-3">Consider bookkeepers as financial organizers, simplifying the process of sorting money into different folders, much like organizing various items in your life. In the business world, these folders are known as categories, and they play a crucial role in providing clarity about the flow of money within a company.</p>
            <p className="text-lg mt-3">Imagine you run a lemonade stand. Every time you make a sale, the money earned goes into the "Money In" category. It's akin to building a reserve for future use. Conversely, when you spend money on lemons or cups, those expenses are categorized under "Money Out." This helps keep track of where the money is going.</p>
            <p className="text-lg mt-3">Now, here's where it becomes more interesting. Bookkeepers may create additional categories, such as "Money Saved." This is comparable to setting aside a portion of your earnings for a specific purpose, like saving up for a future purchase. Essentially, these categories serve as a practical tool for understanding the financial landscape, making it easier for businesses to manage their money wisely.</p>
            <p className="text-lg mt-3">In essence, the categorization process acts as a financial map, providing a clear and straightforward overview of the money journey – how much is coming in, how much is going out, and what is being set aside for future needs. This organizational approach makes it accessible for everyone involved to comprehend and navigate the financial aspects of the business.</p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Balancing the Financial Scale</h2>
            <p className="text-lg mt-3">Think of bookkeeping as the art of maintaining a financial seesaw. Here's how it works: every financial move a business makes, like buying supplies or selling products, tips the seesaw in one direction. Bookkeepers ensure that for every tip towards spending money (the "Money Out" side), there's an equal tip towards earning money (the "Money In" side).</p>
            <p className="text-lg mt-3">Let's say your business spends $50 to buy new inventory – that's a tip towards "Money Out." To balance it, bookkeepers make sure there's a $50 sale (or another source of income) – a tip towards "Money In." It's like a constant game of keeping the seesaw level so that the business doesn't end up spending more than it earns.</p>
            <p className="text-lg mt-3">This balancing act is crucial for financial stability. If the seesaw tips too much towards spending, it could mean the business is in danger of losing more money than it's making. Bookkeepers act as financial referees, making sure the game is fair and both sides of the seesaw are in harmony. This systematic approach prevents financial mishaps and ensures the business stays on solid ground.</p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Solving Money Mysteries – Reconciliation</h2>
            <p className="text-lg mt-3">Consider bookkeepers as financial detectives during the reconciliation process. Picture this: the business keeps its own detailed records of money movements, and the bank has its own set of records. Reconciliation is like comparing these two sets of notes to make sure they match up perfectly.</p>
            <p className="text-lg mt-3">Let's break it down. Imagine your business diary says you earned $100 from sales last week. Now, you check your bank statement, and it also shows $100 coming in. That's great – no mystery here. But what if your diary says $100, and the bank statement says $95? Now, there's a mystery to solve. Bookkeepers dive into these discrepancies, figuring out why the numbers don't match and making sure every penny is accounted for.</p>
            <p className="text-lg mt-3">Think of reconciliation as the business's financial health check-up. Bookkeepers ensure that all the financial organs – the business's records and the bank's records – are working in harmony. If there's a hiccup, they diagnose it and prescribe the right solution to make everything run smoothly again. This meticulous process ensures that the business's financial story is accurate, reliable, and free from any puzzling mysteries.</p>

            <h2 className="font-bold text-3xl text-gray-800 mt-6">Conclusion</h2>
            <p className="text-lg mt-3">In wrapping up our journey through the world of bookkeeping, let's recap the key roles played by diligent bookkeepers. They act as financial organizers, categorizing money moves into folders, providing a clear map of where the money is coming from, where it's going, and what's being saved.</p>
            <p className="text-lg mt-3">Bookkeepers are the seesaw managers, ensuring a delicate balance between money earned and money spent. This balancing act prevents businesses from toppling into financial trouble, maintaining stability and sound financial health.</p>
            <p className="text-lg mt-3">They also double as financial detectives during reconciliation, solving mysteries when the business's records and the bank's records don't quite match. This meticulous process ensures the accuracy and reliability of the business's financial story, free from any puzzling discrepancies.</p>
            <p className="text-lg mt-3">In essence, bookkeepers are the unsung heroes of a business's financial journey, providing clarity, stability, and accuracy in the ever-evolving world of money. Their systematic and organized approach ensures that businesses not only survive but thrive in the intricate dance of financial management.</p>

        </div>
    );
}
import {Link} from "react-router-dom";
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import 'highlight.js/styles/a11y-dark.css';
import {useEffect} from "react";

export default function MarketingPreview2() {
    return(
        <div className="flex flex-row mt-9 border-t-2 border-dotted border-purple-100 pt-9">
            <div className="basis-2/4 px-6">
                <div>
                    <Link to="/m-blog/2">
                        <h1 className="font-bold text-3xl text-gray-800 mb-2">What Is Bookkeeping</h1>
                    </Link>
                    <p className="text-lg text-gray-700 mb-2">
                        In the world of business, bookkeeping plays the role of a friendly guide, helping companies keep their financial ducks in a row. If you've ever wondered, "What is bookkeeping?" let's embark on a journey to unravel this essential practice using straightforward language and relatable examples.
                    </p>
                    <Link to="/m-blog/2" className="text-purple-700 font-bold">Read more</Link>
                </div>
            </div>
            <div className="basis-2/4">
                <img
                    className="max-w-sm shadow-lg border-2 border-black"
                    src="/bookkeeping-image-rVs.jpeg"
                    alt="bookkeeping"
                />
            </div>
        </div>
    );
}
import { Disclosure } from '@headlessui/react'
import GoogleSignIn from "./GoogleSignIn";
import {Link, NavLink} from "react-router-dom";
import SecondaryButton from "./SeconaryButton";
import {post} from "../requests";
import {useState} from "react";

function navLinkClassNames(isActive) {
    let cNames = 'rounded-md px-3 py-2 font-medium text-gray-950 hover:text-gray-400';
    // if (isActive) {
    //     cNames = cNames + ' bg-gray-800 text-white';
    // } else {
    //     cNames = cNames + ' text-gray-800 hover:text-white'; //hover:bg-gray-800
    // }
    return cNames;
}

const SignOut = ({ setUser, setIsLoadingUser }) => {

    const signOut = () => {
        setIsLoadingUser(true);
        post("auth/sign-out")
            .then(() => {
                setUser(null);
                setIsLoadingUser(false);
            });
    }

    return (
        <div>
            <SecondaryButton buttonText="Sign Out" type="submit" onClick={signOut}/>
        </div>
  );
};

const SignIn = () => {
    return (
        <Link
            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            to="/create-or-sign-in">
            Sign In or Sign Up
        </Link>
    );
}

const UserProfile = ({ isSignedIn, setUser, isLoadingUser, setIsLoadingUser }) => {
    const Spinner = () => {
        return (
            <div
                className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
            </div>
        )
    }
    return (
        <div className="flex items-center">
            <div className="flex-shrink-0">

            </div>
            <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                {
                    isLoadingUser
                        ? <Spinner />
                        : isSignedIn
                            ? <SignOut setUser={setUser} setIsLoadingUser={setIsLoadingUser}/>
                            : <SignIn />//<GoogleSignIn setUser={setUser} setIsLoadingUser={setIsLoadingUser}/>
                }
            </div>
        </div>
    )
}

const Navigation = ({ isFreeTier }) => {
    return (
        <div className="flex">
            <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="h-8 align-middle inline"
                         viewBox="0 0 32 32" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                        <g>
                            <rect width="20.134363" height="25.127692" rx="1" ry="1" transform="matrix(1.042993 0 0 1.034715 7.242029 4.660252)" fill="#a963dd" strokeWidth="3"/>
                            <rect width="20" height="25" rx="1" ry="1" transform="matrix(.909102 0 0 0.938823 8.789606 5.892534)" fill="#c796eb" strokeWidth="3"/>
                            <ellipse rx="0.71483" ry="0.714831" transform="translate(23.569115 28.414831)" fill="#8b38c8" strokeWidth="0"/>
                            <ellipse rx="0.71483" ry="0.714831" transform="translate(25.472801 28.414831)" fill="#8b38c8" strokeWidth="0"/>
                        </g>
                        <g transform="translate(-1.789966-2.665231)">
                            <rect width="20.134363" height="25.127692" rx="1" ry="1" transform="matrix(1.042993 0 0 1.034715 5.547937 4.004979)" fill="#a963dd" strokeWidth="3"/>
                            <rect width="20" height="25" rx="1" ry="1" transform="matrix(.890233 0 0 0.931643 7.313611 5.32953)" fill="#c796eb" strokeWidth="3"/>
                        </g>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 10)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 12)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 14)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 16)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 18)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 20)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 22)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 24)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="translate(14.268133 8)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="matrix(.349889 0 0-1 10.193151 4.28555)" fill="none" stroke="#8b38c8"/>
                        <line x1="-6.268133" y1="0" x2="6.731867" y2="0" transform="matrix(.20662 0 0 1 9.295122 5.892534)" fill="none" stroke="#8b38c8"/>
                    </svg>
                    <span className="align-middle text-gray-950 text-2xl font-medium tracking-tight">BANK STATEMENT 2 CSV</span>
                </Link>
            </div>
            <div className="ml-6 flex items-center space-x-4">
                <NavLink
                    key={"PDF Bank Statement Converter"}
                    to={"/"}
                    className={({ isActive }) => navLinkClassNames(isActive)}
                >
                    Converter
                </NavLink>
                <NavLink
                    key={"Pricing"}
                    to={"/pricing"}
                    className={({ isActive }) => navLinkClassNames(isActive)}
                >
                    Pricing
                </NavLink>
                {!isFreeTier &&
                    <NavLink
                        key={"Settings"}
                        to={"/settings"}
                        className={({ isActive }) => navLinkClassNames(isActive)}
                    >
                        Settings
                    </NavLink>}
                <NavLink
                    key={"ContactUs"}
                    to={"/contact-us"}
                    className={({ isActive }) => navLinkClassNames(isActive)}
                >
                    Contact
                </NavLink>
                <NavLink
                    key={"AboutUs"}
                    to={"/about-us"}
                    className={({ isActive }) => navLinkClassNames(isActive)}
                >
                    About
                </NavLink>
            </div>
        </div>
    )
}



export default function Navbar({ isSignedIn, setUser, user, isLoadingUser, setIsLoadingUser, isFreeTier }) {
    return (
        <Disclosure as="nav">
            {({ open }) => (
                <>
                    <div className="mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <Navigation isFreeTier={isFreeTier}/>
                            <div className="flex space-x-4 items-center">
                                { user && <span className="sm:hidden lg:inline text-gray-800 text-right">Welcome {user.email}</span> }
                                <UserProfile isSignedIn={isSignedIn} setUser={setUser} isLoadingUser={isLoadingUser} setIsLoadingUser={setIsLoadingUser} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    )
}

import {CheckIcon} from "@heroicons/react/20/solid";
import {Fragment, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {ChevronDoubleDownIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import Spinner from "../../../components/Spinner";
import {put} from "../../../requests";

function downgrade(tierId, setDowngrading, setShowSuccess, setShowFailure, setSubscription) {
    setDowngrading(true);
    put("users/self/subscriptions/downgrade", tierId)
        .then(resp => {
            if (resp.status === 200) {
                setShowSuccess(true);
                setDowngrading(false);
                setSubscription(resp.data);
            }
        })
        .catch(error => {
            setShowFailure(true);
            setDowngrading(false);
        });
}

export default function DowngradeModal({ open, setOpen, tierToChangeTo, setSubscription }) {
    const cancelButtonRef = useRef(null)
    const [downgrading, setDowngrading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);

    const renderContent = () => {
        if (downgrading) {
            return <div className="flex justify-center"><span className="mr-2">Processing...</span><Spinner /></div>;
        } else if (showSuccess) {
            return(
                <>
                    <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Downgrade to {tierToChangeTo?.name} was successful
                            </Dialog.Title>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={onClose}
                            ref={cancelButtonRef}
                        >
                            Close
                        </button>
                    </div>
                </>
            );
        } else if (showFailure) {
            return (
                <>
                    <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Downgrade to {tierToChangeTo?.name} failed
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Please email me at chris@bankstatement2csv.com and tell me which subscription tier you want to downgrade to. I will resolve the issue ASAP.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={onClose}
                            ref={cancelButtonRef}
                        >
                            Close
                        </button>
                    </div>
                </>
            );
        } else {
            return(
                <>
                    <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                            <ChevronDoubleDownIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Are you sure you want to downgrade to {tierToChangeTo?.name}?
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    You will keep all of your credits. The applicable price change will appear on your next invoice.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                            onClick={() => downgrade(tierToChangeTo.id, setDowngrading, setShowSuccess, setShowFailure, setSubscription)}
                        >
                            Downgrade
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={onClose}
                            ref={cancelButtonRef}
                        >
                            Close
                        </button>
                    </div>
                </>
            );
        }
    }

    const onClose = () => {
        setOpen(false);
        setShowFailure(false);
        setShowSuccess(false);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                { renderContent() }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
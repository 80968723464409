import axios from "axios";

const defaultConfig = {
    baseURL: window.location.hostname.includes("localhost") ? "http://localhost:7070/api" : "https://bankstatement2csv.com/api",
    withCredentials: true,
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 403
};

export function get(url, config) {
    return axios.get(url, { ...defaultConfig, ...config });
}

export function put(url, data, config) {
    return axios.put(url, data, { ...defaultConfig, ...config });
}

export function post(url, data, config) {
    return axios.post(url, data, { ...defaultConfig, ...config });
}

export function postForm(url, data, config) {
    return axios.postForm(url, data, { ...defaultConfig, ...config });
}
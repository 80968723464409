import {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Spinner from "../../components/Spinner";
import {post} from "../../requests";

function checkPassword(password, newErrors) {
    if (password === null || password.length < 9) {
        newErrors.push("Password must be at least 9 characters.");
    }
}

function checkPasswordConfirmation(passwordConfirmation, password, newErrors) {
    if (passwordConfirmation !== password) {
        newErrors.push("Password confirmation and password must match.");
    }
}

function resetPassword(password, passwordConfirmation, uuid, setIsResettingPassword, setResetSent, setErrors) {
    const newErrors = [];
    checkPassword(password, newErrors);
    checkPasswordConfirmation(passwordConfirmation, password, newErrors);

    if (newErrors.length === 0) {
        setErrors(newErrors);
        setIsResettingPassword(true);
        post("auth/reset-password", {newPassword:password, uuid:uuid})
            .then(response => {
                setResetSent(true);
                setIsResettingPassword(false);
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setErrors([error.response.data.details.message]);
                    setIsResettingPassword(false);
                }
            })
    } else {
        setErrors(newErrors);
    }
}

function ResetPasswordButton({ isResettingPassword, password, passwordConfirmation, uuid, setIsResettingPassword, setResetSent, setErrors }) {
    return(
        <>
            { isResettingPassword ?
                <Spinner classes="border-t-8 border-l-8 border-b-8 border-r-8 border-t-purple-500 border-l-purple-500 border-b-purple-500 h-8 w-8" />
                :
                <button
                    className="font-medium bg-purple-400 rounded-md text-lg px-4 py-4 border-2 border-gray-950 shadow-[3px_3px_0_black] transition-transform transition-shadow hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                    type="button"
                    onClick={() => resetPassword(password.current.value, passwordConfirmation.current.value, uuid, setIsResettingPassword, setResetSent, setErrors)}
                >
                    Reset Your Password
                </button>
            }
        </>
    );
}

export default function ResetPasswordPage({ user }) {
    const [errors, setErrors] = useState([]);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [resetSent, setResetSent] = useState(false);

    const password = useRef();
    const passwordConfirmation = useRef();

    const navigate = useNavigate();

    const { uuid } = useParams();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    return(
        <div className="border-2 border-black rounded-lg shadow-xl max-w-5xl mx-auto mt-9">
            <h1 className="text-center text-3xl font-bold tracking-tight text-gray-800 py-4 bg-teal-400 rounded-t border-b-2 border-black">
                { resetSent ? "Password Reset Successful" : "Reset Your Password for Bank Statement 2 CSV" }
            </h1>
            <div className="p-6">
                {errors.map(error => {
                    return(
                        <p key={error} className="text-red-500 mt-2">
                            {error}
                        </p>
                    );
                })}
                <div className="mt-4">
                    {
                        resetSent ?
                            <p className="text-lg mt-2">Your password was successfully reset.</p>
                            :
                            <>
                                <div>
                                    <label className="font-bold">
                                        Password
                                        <input
                                            className="font-normal appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                                            type="password"
                                            ref={password}
                                        />
                                    </label>
                                </div>
                                <div className="mt-4">
                                    <label className="font-bold mt-4">
                                        Password Confirmation
                                        <input
                                            className="font-normal appearance-none rounded w-full border mt-2 py-1 px-2 shadow-inner focus:outline-4 focus:outline-dashed focus:outline-purple-400 focus:outline-offset-4"
                                            type="password"
                                            ref={passwordConfirmation}
                                        />
                                    </label>
                                </div>
                            </>
                    }
                </div>
            </div>
            <div className="mt-4 border-t-2 border-black rounded-b p-6 text-center bg-gray-200">
                {
                    resetSent ?
                        <Link
                            to="/sign-in"
                            className="font-medium max-w-xs bg-purple-400 rounded-md px-4 py-4 border-2 border-gray-950 text-center text-2xl
                             inline-block w-full
                             shadow-[3px_3px_0_black]
                             transition-transform transition-shadow
                             hover:translate-x-1 hover:translate-y-1 hover:shadow-none hover:cursor-pointer"
                        >
                            Sign In
                        </Link>
                        :
                        <ResetPasswordButton
                            password={password}
                            passwordConfirmation={passwordConfirmation}
                            setIsResettingPassword={setIsResettingPassword}
                            isResettingPassword={isResettingPassword}
                            setResetSent={setResetSent}
                            setErrors={setErrors}
                            uuid={uuid}/>
                }
            </div>
        </div>
    );
}
export default function PrivacyPolicyPage() {
    return (
        <div>
            <h1 className="text-center text-3xl">Privacy Policy</h1>
            <p className="text-center mb-5">Last updated 9/1/2023</p>
            <p>
                This privacy notice for <strong><strong>Affogato Software LLC</strong></strong> (&quot;
                <strong>we</strong> ,&quot; &quot; <strong>us</strong> ,&quot; or &quot; <strong>our</strong>
                &quot;), describes how and why we might collect, store, use, and/or share (&quot; <strong>process</strong>
                &quot;) your information when you use our services (&quot; <strong>Services</strong> &quot;), such as when you:
            </p>
            <p><strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy
                rights and choices. If you do not agree with our policies and practices, please do not use our Services.
                If you still have any questions or concerns, please contact us at <strong><strong>chris@bankstatement2csv.com</strong></strong>.
            </p>
            <br/>
            <p><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p>
            <p><strong>Personal information you disclose to us</strong></p>
            <p><strong>*In Short:</strong> We collect personal information that you provide to us.*</p>
            <p>We collect personal information that you voluntarily provide to us when you register on the Services,
                express an interest in obtaining information about us or our products and Services, when you participate
                in activities on the Services, or otherwise when you contact us.</p>
            <p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>
            <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify
                us of any changes to such personal information.</p>
            <p><strong>Information automatically collected</strong></p>
            <p><strong>*In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser
                and device characteristics — is collected automatically when you visit our Services.*</p>
            <p>We automatically collect certain information when you visit, use, or navigate the Services. This
                information does not reveal your specific identity (like your name or contact information) but may
                include device and usage information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name, country, location, information
                about how and when you use our Services, and other technical information. This information is primarily
                needed to maintain the security and operation of our Services, and for our internal analytics and
                reporting purposes.</p>
            <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
            <p><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></p>
            <p><strong>*In Short:</strong> We process your information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent.*</p>
            <p><strong>We process your personal information for a variety of reasons, depending on how you interact with
                our Services, including:</strong></p>
            <p><strong>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></p>
            <p><strong>*In Short:</strong> We may share information in specific situations described in this section
                and/or with the following third parties.*</p>
            <p>We may need to share your personal information in the following situations:</p>
            <ul>
                <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with,
                    or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                    portion of our business to another company.
                </li>
                <li><strong>Affiliates.</strong> We may share your information with our affiliates, in which case we
                    will require those affiliates to honor this privacy notice. Affiliates include our parent company
                    and any subsidiaries, joint venture partners, or other companies that we control or that are under
                    common control with us.
                </li>
                <li><strong>Business Partners.</strong> We may share your information with our business partners to
                    offer you certain products, services, or promotions.
                </li>
            </ul>
            <p><strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></p>
            <p><strong>*In Short:</strong> We may use cookies and other tracking technologies to collect and store your
                information.*</p>
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
                information. Specific information about how we use such technologies and how you can refuse certain
                cookies is set out in our Cookie Notice.</p>
            <p><strong>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></p>
            <p><strong>*In Short:</strong> If you choose to register or log in to our Services using a social media
                account, we may have access to certain information about you.*</p>
            <p>Our Services offer you the ability to register and log in using your third-party social media account
                details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain
                profile information about you from your social media provider. The profile information we receive may
                vary depending on the social media provider concerned, but will often include your name, email address,
                friends list, and profile picture, as well as other information you choose to make public on such a
                social media platform.</p>
            <p>We will use the information we receive only for the purposes that are described in this privacy notice or
                that are otherwise made clear to you on the relevant Services. Please note that we do not control, and
                are not responsible for, other uses of your personal information by your third-party social media
                provider. We recommend that you review their privacy notice to understand how they collect, use, and
                share your personal information, and how you can set your privacy preferences on their sites and
                apps.</p>
            <p><strong>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</strong></p>
            <p><strong>*In Short:</strong> We may transfer, store, and process your information in countries other than
                your own.*</p>
            <p>Our servers are located in. If you are accessing our Services from outside, please be aware that your
                information may be transferred to, stored, and processed by us in our facilities and by those third
                parties with whom we may share your personal information (see &quot;<a
                    href="http://localhost:63342/bank-statement-converter-ui/public/termsAndConditions.html?_ijt=abmaeth4qlrb2nkfvhn9g0r7vf&amp;_ij_reload=RELOAD_ON_SAVE#whoshare">WHEN
                    AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>&quot; above), in and other countries.</p>
            <p>If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries
                may not necessarily have data protection laws or other similar laws as comprehensive as those in your
                country. However, we will take all necessary measures to protect your personal information in accordance
                with this privacy notice and applicable law.</p>
            <p><strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
            <p><strong>*In Short:</strong> We keep your information for as long as necessary to fulfill the purposes
                outlined in this privacy notice unless otherwise required by law.*</p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in
                this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                accounting, or other legal requirements).</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either
                delete or anonymize such information, or, if this is not possible (for example, because your personal
                information has been stored in backup archives), then we will securely store your personal information
                and isolate it from any further processing until deletion is possible.</p>
            <p><strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong></p>
            <p><strong>*In Short:</strong> We do not knowingly collect data from or market to children under 18 years of
                age.*</p>
            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services,
                you represent that you are at least 18 or that you are the parent or guardian of such a minor and
                consent to such minor dependent’s use of the Services. If we learn that personal information from users
                less than 18 years of age has been collected, we will deactivate the account and take reasonable
                measures to promptly delete such data from our records. If you become aware of any data we may have
                collected from children under age 18, please contact us at <strong><strong>chris@bankstatement2csv.com</strong></strong>.</p>
            <p><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
            <p><strong>*In Short:</strong> You may review, change, or terminate your account at any time.*</p>
            <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                information, you also have the right to complain to your <a
                    href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">Member State
                    data protection authority</a> or <a
                    href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">UK
                    data protection authority</a>.</p>
            <p>If you are located in Switzerland, you may contact the <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal Data Protection and Information
                Commissioner</a>.</p>
            <p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal
                information, which may be express and/or implied consent depending on the applicable law, you have the
                right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us
                by using the contact details provided in the section &quot;<a
                    href="http://localhost:63342/bank-statement-converter-ui/public/termsAndConditions.html?_ijt=abmaeth4qlrb2nkfvhn9g0r7vf&amp;_ij_reload=RELOAD_ON_SAVE#contact">HOW
                    CAN YOU CONTACT US ABOUT THIS NOTICE?</a>&quot; below.</p>
            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal
                nor, when applicable law allows, will it affect the processing of your personal information conducted in
                reliance on lawful processing grounds other than consent.</p>
            <p><strong>Account Information</strong></p>
            <p>If you would at any time like to review or change the information in your account or terminate your
                account, you can:</p>
            <p>Upon your request to terminate your account, we will deactivate or delete your account and information
                from our active databases. However, we may retain some information in our files to prevent fraud,
                troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with
                applicable legal requirements.</p>
            <p><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data
                about your online browsing activities monitored and collected. At this stage no uniform technology
                standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently
                respond to DNT browser signals or any other mechanism that automatically communicates your choice not to
                be tracked online. If a standard for online tracking is adopted that we must follow in the future, we
                will inform you about that practice in a revised version of this privacy notice.</p>
            <p><strong>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></p>
            <p><strong>*In Short:</strong> Yes, if you are a resident of California, you are granted specific rights
                regarding access to your personal information.*</p>
            <p>California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law, permits our
                users who are California residents to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we disclosed to third parties for direct
                marketing purposes and the names and addresses of all third parties with which we shared personal
                information in the immediately preceding calendar year. If you are a California resident and would like
                to make such a request, please submit your request in writing to us using the contact information
                provided below.</p>
            <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you
                have the right to request removal of unwanted data that you publicly post on the Services. To request
                removal of such data, please contact us using the contact information provided below and include the
                email address associated with your account and a statement that you reside in California. We will make
                sure the data is not publicly displayed on the Services, but please be aware that the data may not be
                completely or comprehensively removed from all our systems (e.g., backups, etc.).</p>
            <p><strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong></p>
            <p><strong>*In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant
                laws.*</p>
            <p>We may update this privacy notice from time to time. The updated version will be indicated by an
                updated &quot;Revised&quot; date and the updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may notify you either by prominently posting a
                notice of such changes or by directly sending you a notification. We encourage you to review this
                privacy notice frequently to be informed of how we are protecting your information.</p>
            <p><strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></p>
            <p>If you have questions or comments about this notice, you may email us
                at <strong><strong>chris@bankstatement2csv.com</strong></strong></p>
        </div>
    )
}